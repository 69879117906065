import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import { useState } from "react";
import { Link } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import 'bootstrap/dist/css/bootstrap.css'; 
import Carousel from 'react-bootstrap/Carousel'; 

const RegisterPageComponent = ({
  registerUserApiRequest,
  reduxDispatch,
  setReduxUserState,
}) => {
  const [validated, setValidated] = useState(false);
  const [registerUserResponseState, setRegisterUserResponseState] = useState({
    success: "",
    error: "",
    loading: false,
  });
  const [passwordsMatchState, setPasswordsMatchState] = useState(true);
  const config = require("../../constants/constants.js")

  const onChange = () => {
    const password = document.querySelector("input[name=password]");
    const confirmPassword = document.querySelector("input[name=confirmPassword]");
    if (confirmPassword.value === password.value) {
      setPasswordsMatchState(true);
    } else {
      setPasswordsMatchState(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget.elements;
    const email = form.email.value;
    const name = form.name.value;
    const lastName = form.lastName.value;
    const password = form.password.value;
    if (
      event.currentTarget.checkValidity() === true &&
      email &&
      password &&
      name &&
      lastName &&
      form.password.value === form.confirmPassword.value
    ) {
      setRegisterUserResponseState({ loading: true });
      registerUserApiRequest(name, lastName, email, password)
        .then((data) => {
          setRegisterUserResponseState({
            success: data.success,
            loading: false,
          });
          reduxDispatch(setReduxUserState(data.userCreated));
          
        })
        .catch((er) =>
          setRegisterUserResponseState({
            error: er.response.data.message
              ? er.response.data.message
              : er.response.data,
          })
        );
    }

    setValidated(true);
  };
  return (
    <Container className="px-0">
    <Row
      g-0
      className="pt-2 pt-md-5 w-100 px-4 px-xl-0 position-relative"
    >
      <Col
        xs={{ order: 2 }}
        md={{ size: 4, order: 1 }}
        tag="aside"
        className="pb-5 mb-5 pb-md-0 mb-md-0 mx-auto mx-md-0"
      >

        <Carousel> 
          <Carousel.Item interval={2500}> 
            <img 
              className="d-block w-100"
              src={config.PAGE_REGISTRA_SLIDE_1_URL}
              alt="immmagine del prodotto"
            /> 
            <Carousel.Caption> 
              <h3>{config.PAGE_REGISTRA_SLIDE_1_TITOLO}</h3> 
              <p>{config.PAGE_REGISTRA_SLIDE_1_SOTTOTITOLO}</p> 
            </Carousel.Caption> 
          </Carousel.Item> 
          <Carousel.Item interval={2500}> 
            <img 
              className="d-block w-100"
              src={config.PAGE_REGISTRA_SLIDE_2_URL}
              alt="immmagine del prodotto"
            /> 
            <Carousel.Caption> 
              <h3>{config.PAGE_REGISTRA_SLIDE_2_TITOLO}</h3> 
              <p>{config.PAGE_REGISTRA_SLIDE_2_SOTTOTITOLO}</p> 
            </Carousel.Caption> 
          </Carousel.Item> 
          <Carousel.Item interval={2500}> 
            <img 
              className="d-block w-100"
              src={config.PAGE_REGISTRA_SLIDE_3_URL}
              alt="immmagine del prodotto"
            /> 
            <Carousel.Caption> 
              <h3>{config.PAGE_REGISTRA_SLIDE_3_TITOLO}</h3> 
              <p>{config.PAGE_REGISTRA_SLIDE_3_SOTTOTITOLO}</p> 
            </Carousel.Caption> 
          </Carousel.Item> 
        </Carousel> 
      </Col>

      <Col
        xs={{ order: 1 }}
        md={{ size: 7, offset: 1 }}
        tag="section"
        className="py-5 mb-5 py-md-0 mb-md-0"
      >

          <h1>Crea account</h1>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="validationCustom01">
              <Form.Label>Nome</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="inserisci il tuo nome"
                name="name"
              />
              <Form.Control.Feedback type="invalid">
                Prego inserisci il tuo nome
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicLastName">
              <Form.Label>Cognome</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="inserisci il tuo cognome"
                name="lastName"
              />
              <Form.Control.Feedback type="invalid">
                Prego inserisci il tuo cognome
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                name="email"
                required
                type="email"
                placeholder="inserisci email"
              />
              <Form.Control.Feedback type="invalid">
                inserisci un indirizzo email valido
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                name="password"
                required
                type="password"
                placeholder="Password"
                minLength={6}
                onChange={onChange}
                isInvalid={!passwordsMatchState}
              />
              <Form.Control.Feedback type="invalid">
                Prego inserire una password valida
              </Form.Control.Feedback>
              <Form.Text className="text-muted">
                la Password deve avere più 5 caratteri
              </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPasswordRepeat">
              <Form.Label>Ripeti Password</Form.Label>
              <Form.Control
                name="confirmPassword"
                required
                type="password"
                placeholder="ripeti la Password"
                minLength={6}
                onChange={onChange}
                isInvalid={!passwordsMatchState}
              />
              <Form.Control.Feedback type="invalid">
                le due password devono corrispondere
              </Form.Control.Feedback>
            </Form.Group>

            <Row className="pb-2">
              <Col>
                Hai già un account?
                <Link to={"/login"}> Login </Link>
              </Col>
            </Row>

            <Button type="submit">
              {registerUserResponseState &&
              registerUserResponseState.loading === true ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                ""
              )}
              Crea nuovo account
            </Button>
            <Alert show={registerUserResponseState && registerUserResponseState.error === "user exists"} variant="danger">
              Già esiste un Utente con questa email!
            </Alert>
            <Alert show={registerUserResponseState && registerUserResponseState.success === "User created"} variant="info">
              Utente creato
            </Alert>
          </Form>

      </Col>
    </Row>
    
    <Row className="mt-5 justify-content-md-center">
      <Col md={6}>
      </Col>
    </Row>

  </Container>

  );
};

export default RegisterPageComponent;
