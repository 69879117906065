import {
  Navbar,
  Nav,
  Container,
  NavDropdown,
  Badge,
  Form,
  DropdownButton,
  Dropdown,
  Button,
  InputGroup,
} from "react-bootstrap";

import { LinkContainer } from "react-router-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../redux/actions/userActions.js";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getCategories } from "../redux/actions/categoryActions.js";
import socketIOClient from "socket.io-client";
import { setChatRooms, setSocket, setMessageReceived, removeChatRoom } from "../redux/actions/chatActions.js";

const config = require("../constants/constants.js")
let site_style_active = config.SITE_STYLE_ACTIVE;

const HeaderComponentStyleC = () => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userRegisterLogin);
  const itemsCount = useSelector((state) => state.cart.itemsCount);
  const { categories } = useSelector((state) => state.getCategories);
  const { messageReceived } = useSelector((state) => state.adminChat);

  const [searchCategoryToggle, setSearchCategoryToggle] = useState("Tutte le categorie");
  const [searchQuery, setSearchQuery] = useState("");

  const navigate = useNavigate();

//  const url_site_image_logo = "../logoMyshop.png"; 
  const url_site_image_logo = config.URL_IMAGES_SITE+"logoMyShop.png";
  const url_site_image_shopnow = config.URL_IMAGES_SITE+"shop-now.png";
  const url_customer_image_logo = config.URL_IMAGES_CUSTOMER+"logo-cust"+config.CUSTOMER_CODE+".png";  // logo-cust0001.png 

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  const submitHandler = (e) => {
     if (e.keyCode && e.keyCode !== 13) return;
     e.preventDefault();
     if (searchQuery.trim()) {
         if (searchCategoryToggle === "Tutte le categorie") {
             navigate(`/product-list/search/${searchQuery}`);
         } else {
             navigate(`/product-list/category/${searchCategoryToggle.replaceAll("/", ",")}/search/${searchQuery}`);
         }
     } else if (searchCategoryToggle !== "Tutte le categorie") {
         navigate(`/product-list/category/${searchCategoryToggle.replaceAll("/", ",")}`);
     } else {
         navigate("/product-list");
     }
  }

  useEffect(() => {
      if (userInfo.isAdmin) {
          var audio = new Audio("/audio/chat-msg.mp3");
          const socket = socketIOClient();
          socket.emit("admin connected with server", "Admin" + Math.floor(Math.random() * 1000000000000));
          socket.on("server sends message from client to admin", ({user, message}) => {
              dispatch(setSocket(socket));
        //   let chatRooms = {
        //     fddf54gfgfSocketID: [{ "client": "dsfdf" }, { "client": "dsfdf" }, { "admin": "dsfdf" }],
        //   };
            dispatch(setChatRooms(user, message));      
             dispatch(setMessageReceived(true));  
             audio.play();
          })
          socket.on("disconnected", ({reason, socketId}) => {
            //   console.log(socketId, reason)
            dispatch(removeChatRoom(socketId));
          })
          return () => socket.disconnect();
      }
  },[userInfo.isAdmin])

    // CAMBIO STILE DEL SITO : goto subdomains
  // 
  //    switch stile: 
  //        ART       A       subdomain   art.
  //        CLASSIC   A       subdomain   classic.
  //        MODERN    A       subdomain   modern.
  // 
  const handleSelectStyle=(value)=>{
    console.log('Style change in progress!')
    //console.log(value);
    site_style_active=value;
    //console.log(site_style_active)
    if(site_style_active === 'A'){
      //console.log('goto Art style')
      window.location = "https://art.myshoponline.it/"
    } else if(site_style_active === 'B') {
      //console.log('goto Classic style')
      window.location = "https://classic.myshoponline.it/"
    } else if(site_style_active === 'C') {
      //console.log('goto Jazz style')
      window.location = "https://modern.myshoponline.it/"
    }
  } 

  // CAMBIO STILE DEL SITO
  // 
  //    se config.ENABLE_SWITCH_STILE = true   => appare un combobox per la selezione dello stile e poi va al subdomain      
  //    se config.ENABLE_SWITCH_STILE = false  => non appare un combobox per la selezione dello stile       
  //
  function SwitchStile(props) {
    if (!props) {
      return null;
    }
    return (
    <NavDropdown title="Stile" id="collasible-nav-dropdown" onSelect={handleSelectStyle}
      style={{
        color: "white",
        marginLeft: "20px",
      }}
    >
        <NavDropdown.Item eventKey="A"> Art </NavDropdown.Item>
        <NavDropdown.Item eventKey="B"> Classic </NavDropdown.Item>
        <NavDropdown.Item eventKey="C"> Modern</NavDropdown.Item>
    </NavDropdown>
    );
  }

       {/* 
              PER ESSERE RESPONSIVE ANCHE NELLE 2 RIGHE DI MENU 
              devo gestire 
                height: "40px",
                height: "auto",
        */}

  return (
    <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand href="/">
            <img src={url_customer_image_logo} alt="bug" height={40} />       
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </Navbar.Brand>
        </LinkContainer>
        
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <InputGroup>
              <DropdownButton id="dropdown-basic-button" title={searchCategoryToggle}>
                  <Dropdown.Item onClick={() => setSearchCategoryToggle("Tutte le categorie")}>Tutte le categorie</Dropdown.Item>
                {categories.map((category, id) => (
                  <Dropdown.Item key={id} onClick={() => setSearchCategoryToggle(category.name)}>{category.name}</Dropdown.Item>
                ))}
              </DropdownButton>
              <Form.Control onKeyUp={submitHandler} onChange={(e) => setSearchQuery(e.target.value)} type="text" placeholder="prodotto ..." />
              <Button onClick={submitHandler} variant="warning">
                <i className="bi bi-search text-dark"></i>
              </Button>
            </InputGroup>

            {/*     CAMBIO STILE DEL SITO : appare il combobox di selezione Stile     */}
            {SwitchStile(config.ENABLE_SWITCH_STILE)}

          </Nav>

          <LinkContainer to="/product-list">
            <Navbar.Brand href="/product-list">
              <img src={url_site_image_shopnow} alt="bug" height={40} />       
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </Navbar.Brand>
          </LinkContainer>

          <Nav>

            {userInfo.isAdmin ? (
              <LinkContainer to="/admin/orders">
                <Nav.Link>
                  Admin
                  {messageReceived && <span className="position-absolute top-1 start-10 translate-middle p-2 bg-danger border border-light rounded-circle"></span>}
                  
                </Nav.Link>
              </LinkContainer>
            ) : userInfo.name && !userInfo.isAdmin ? (
              <NavDropdown
                title={`${userInfo.name} ${userInfo.lastName}`}
                id="collasible-nav-dropdown"
              >
                <NavDropdown.Item
                  eventKey="/user/my-orders"
                  as={Link}
                  to="/user/my-orders"
                >
                  Ordini
                </NavDropdown.Item>
                <NavDropdown.Item eventKey="/user" as={Link} to="/user">
                  Profilo
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => dispatch(logout())}>
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            ) : (
              <>
                <LinkContainer to="/login">
                  <Nav.Link>Login</Nav.Link>
                </LinkContainer>
              </>
            )}

            <LinkContainer to="/cart">
              <Nav.Link>
                <Badge pill bg="danger">
                  {itemsCount === 0 ? "" : itemsCount}
                </Badge>
                <i className="bi bi-cart-dash"></i>
                <span className="ms-1">Carrello</span>
              </Nav.Link>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>

);
};

export default HeaderComponentStyleC;
