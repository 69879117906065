const DB_URI = "mongodb+srv://xxx:xxx@cluster0.xxx.mongodb.net/?retryWrites=true&w=majority";
const API = process.env.NODE_ENV === "production"
    ? "https://xxx.vercel.app/api"
    : "http://localhost:3000/api";

const GOOGLE_CLIENT_ID = "xxx-xxx.apps.googleusercontent.com";
const GOOGLE_CLIENT_SECRET = "xxx";

// identificazione Myshop
const TITLE_PRODUCT = "MyShop";

// impostazione stile SITO e abilitazione comboBox x cambiarlo
let SITE_STYLE_ACTIVE="A"
const ENABLE_SWITCH_STILE = true;
//const ENABLE_SWITCH_STILE = false;

// prelievo IMMAGINI SITO da www.shoppingclick.it  (non utilizzato)
const URL_IMAGES_SITE = "https://www.shoppingclick.it/storage-images/site-myshoponline-it/";

// identificazione cliente
const CUSTOMER = "EXPERTS elettronica";
const CUSTOMER_CODE = "0001";
const CUSTOMER_VIA = "via Roma 10";
const CUSTOMER_CITTA = "20121 Milano";
const CUSTOMER_EMAIL = "informazioni@expert-elettronica.it";

const CUSTOMER_SLIDE_URL_ARRAY = [
  "https://www.shoppingclick.it/storage-images/customer-0001/categorie/categoria-abbigliamentodonna.jpg",
  "https://www.shoppingclick.it/storage-images/customer-0001/categorie/categoria-accessoridonna.jpg",
  "https://www.shoppingclick.it/storage-images/customer-0001/categorie/categoria-attrezzigiardino.jpg",
  "https://www.shoppingclick.it/storage-images/customer-0001/categorie/categoria-computers.jpg",
  "https://www.shoppingclick.it/storage-images/customer-0001/categorie/categoria-games.jpg",
  "https://www.shoppingclick.it/storage-images/customer-0001/categorie/categoria-lavatrici.jpg",
  "https://www.shoppingclick.it/storage-images/customer-0001/categorie/categoria-trapani.jpg",
  "https://www.shoppingclick.it/storage-images/customer-0001/categorie/categoria-tablets.jpg",
];


// pagine di Menu
const PAGE_LOGIN_TITOLO = "il miglior rapporto qualità prezzo";
const PAGE_LOGIN_TESTO = "Tutti i giorni con passione selezioniamo i migliori prodotti.  \
Proponiamo prodotti all'avanguardia con prezzi competitivi e certifichiamo assistenza qualificata. \
I nostri prodotti sono frutto di accurata selezione e arrivano dalla nostra elevata esperienza. \
Le conoscenze offerte dal nostro team non sono seconde a nessuno. Il nostro team di ricerca e sviluppo lavora costantemente per portare nuovi prodotti e soluzioni interessanti sul mercato";
const PAGE_LOGIN_SLIDE_1_URL = "https://www.shoppingclick.it/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide25.jpg";
const PAGE_LOGIN_SLIDE_1_TITOLO = "prodotti disponibili nel punto vendita";
const PAGE_LOGIN_SLIDE_1_SOTTOTITOLO = "trovate tutte le nostre selezioni";
const PAGE_LOGIN_SLIDE_2_URL = "https://www.shoppingclick.it/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide26.jpg";
const PAGE_LOGIN_SLIDE_2_TITOLO = "i migliori prodotti";
const PAGE_LOGIN_SLIDE_2_SOTTOTITOLO = "delle marche più prestigiose";
const PAGE_LOGIN_SLIDE_3_URL = "https://www.shoppingclick.it/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide27.jpg";
const PAGE_LOGIN_SLIDE_3_TITOLO = "prodotti ordinabili online";
const PAGE_LOGIN_SLIDE_3_SOTTOTITOLO = "qualsiasi variante in pochi giorni a casa vostra";

const PAGE_REGISTRA_TITOLO = "il miglior rapporto qualità prezzo";
const PAGE_REGISTRA_TESTO = "Tutti i giorni con passione selezioniamo i migliori prodotti.  \
Proponiamo prodotti all'avanguardia con prezzi competitivi e certifichiamo assistenza qualificata. \
I nostri prodotti sono frutto di accurata selezione e arrivano dalla nostra elevata esperienza. \
Le conoscenze offerte dal nostro team non sono seconde a nessuno. Il nostro team di ricerca e sviluppo lavora costantemente per portare nuovi prodotti e soluzioni interessanti sul mercato";
const PAGE_REGISTRA_SLIDE_1_URL = "https://www.shoppingclick.it/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide18.jpg";
const PAGE_REGISTRA_SLIDE_1_TITOLO = "prodotti disponibili nel punto vendita";
const PAGE_REGISTRA_SLIDE_1_SOTTOTITOLO = "trovate tutte le nostre selezioni";
const PAGE_REGISTRA_SLIDE_2_URL = "https://www.shoppingclick.it/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide19.jpg";
const PAGE_REGISTRA_SLIDE_2_TITOLO = "i migliori prodotti";
const PAGE_REGISTRA_SLIDE_2_SOTTOTITOLO = "delle marche più prestigiose";
const PAGE_REGISTRA_SLIDE_3_URL = "https://www.shoppingclick.it/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide18.jpg";
const PAGE_REGISTRA_SLIDE_3_TITOLO = "prodotti ordinabili online";
const PAGE_REGISTRA_SLIDE_3_SOTTOTITOLO = "qualsiasi variante in pochi giorni a casa vostra";


const PAGE_HOME_TITOLO = "La nostra passione, la nostra esperienza";
const PAGE_HOME_TESTO = "Siamo attori del nosto successo. Con passione ci rivolgiamo ad un Clientele selezionata e proponiamo prodotti in cui crediamo. \
E' la nostra selezione, basata su anni di esperienza. Ai prezzi miliori proponiamo il miglior rapporto qualità/prezzo del mercato. \
Tutti i giorni selezioniamo il meglio per te e anche su ordinazione reperiamo tutti i migliori prodotti. \
Solo da noi sei sicuro di avere il meglio, alle mgliori condizioni di mercato";
const PAGE_HOME_SLIDE_1_URL = "https://www.shoppingclick.it/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide13.jpg";
const PAGE_HOME_SLIDE_1_TITOLO = "i prodotti migliori";
const PAGE_HOME_SLIDE_1_SOTTOTITOLO = "la nostra selezione in negozio";
const PAGE_HOME_SLIDE_2_URL = "https://www.shoppingclick.it/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide15.jpg";
const PAGE_HOME_SLIDE_2_TITOLO = "disponibile qualsiasi variante";
const PAGE_HOME_SLIDE_2_SOTTOTITOLO = "la ordinerai online tu stesso";
const PAGE_HOME_SLIDE_3_URL = "https://www.shoppingclick.it/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide16.jpg";
const PAGE_HOME_SLIDE_3_TITOLO = "qualsiasi variante la ordineremo per te ";
const PAGE_HOME_SLIDE_3_SOTTOTITOLO = "e in pochissimo tempo sarà disponibile in negozio";

const PAGE_CHISIAMO_TITOLO = "La nostra storia";
const PAGE_CHISIAMO_TESTO = "Fondato nel 1986, il nostro negozio offre prodotti innovativi, accessibili e di qualità assicurata. \
Sono i tre fattori che riteniamo cruciali. Forniamo costantemente alta qualità e consulenza continua ai clienti. \
Il nostro team ha una vasta conoscenza del settore. Il nostro obiettivo è semplice: fornire ai nostri clienti prodotti di altissima qualità ai prezzi più competitivi. Per fare un ulteriore passo avanti, tutti i nostri prodotti sono scelti e certificati. \
Le conoscenze offerte dal nostro team non sono seconde a nessuno. Il nostro team di ricerca e sviluppo lavora costantemente per portare nuovi prodotti e soluzioni interessanti sul mercato";
const PAGE_CHISIAMO_SLIDE_1_URL = "https://www.shoppingclick.it/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide17.jpg";
const PAGE_CHISIAMO_SLIDE_1_TITOLO = "competenza";
const PAGE_CHISIAMO_SLIDE_1_SOTTOTITOLO = "investiamo continuamente nella formazione del nostro Team";
const PAGE_CHISIAMO_SLIDE_2_URL = "https://www.shoppingclick.it/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide20.jpg";
const PAGE_CHISIAMO_SLIDE_2_TITOLO = "professionalità";
const PAGE_CHISIAMO_SLIDE_2_SOTTOTITOLO = "competenza al tuo servizio";
const PAGE_CHISIAMO_SLIDE_3_URL = "https://www.shoppingclick.it/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide21.jpg";
const PAGE_CHISIAMO_SLIDE_3_TITOLO = "gentilezza";
const PAGE_CHISIAMO_SLIDE_3_SOTTOTITOLO = "il nostro Team ti aspetta per rispondere al meglio alle tue richieste";

const PAGE_CONTATTI_TITOLO = "Contatti";
const PAGE_CONTATTI_TESTO = "saremo lieti di risponderti al più presto";
const PAGE_CONTATTI_SLIDE_1_URL = "https://www.shoppingclick.it/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide22.jpg";
const PAGE_CONTATTI_SLIDE_1_TITOLO = "i prodotti migliori";
const PAGE_CONTATTI_SLIDE_1_SOTTOTITOLO = "la nostra esperienza a disposizione per la tua scelta";
const PAGE_CONTATTI_SLIDE_2_URL = "https://www.shoppingclick.it/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide23.jpg";
const PAGE_CONTATTI_SLIDE_2_TITOLO = "le marche più prestigiose";
const PAGE_CONTATTI_SLIDE_2_SOTTOTITOLO = "selezione dei migliori brand del mercato";
const PAGE_CONTATTI_SLIDE_3_URL = "https://www.shoppingclick.it/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide24.jpg";
const PAGE_CONTATTI_SLIDE_3_TITOLO = "il miglior rapporto qualità prezzo";
const PAGE_CONTATTI_SLIDE_3_SOTTOTITOLO = "qualsiasi variante in pochi giorni a casa vostra";

const PAGE_BRAND_TITOLO = "le nostre marche";
const PAGE_BRAND_TESTO = "Tutti i giorni con passione selezioniamo le migliori marche. \
Nel nostro negozio è essenziale la scelta dei brand di maggior qualità e la selezione dei prodotti con il miglior rapporto qualità prezzo. \
Tutto ciò che offriamo è frutto di accurata selezione e arrivano dalla nostra elevata esperienza. \
Naturalmente qualsiasi prodotto è disponibile su ordinazione. Il nostro team di ricerca e sviluppo lavora costantemente per portare nuovi prodotti e le soluzioni più competitive.";
const PAGE_BRAND_SLIDE_1_URL = "https://www.shoppingclick.it/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide7.jpg";
const PAGE_BRAND_SLIDE_1_TITOLO = "prodotti disponibili nel punto vendita";
const PAGE_BRAND_SLIDE_1_SOTTOTITOLO = "trovate tutte le nostre selezioni";
const PAGE_BRAND_SLIDE_2_URL = "https://www.shoppingclick.it/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide8.jpg";
const PAGE_BRAND_SLIDE_2_TITOLO = "i migliori prodotti";
const PAGE_BRAND_SLIDE_2_SOTTOTITOLO = "delle marche più prestigiose";
const PAGE_BRAND_SLIDE_3_URL = "https://www.shoppingclick.it/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide9.jpg";
const PAGE_BRAND_SLIDE_3_TITOLO = "prodotti ordinabili online";
const PAGE_BRAND_SLIDE_3_SOTTOTITOLO = "qualsiasi variante in pochi giorni a casa vostra";

const PAGE_PRODOTTI_TITOLO = "il miglior rapporto qualità prezzo";
const PAGE_PRODOTTI_TESTO = "Tutti i giorni con passione selezioniamo i migliori prodotti.  \
Proponiamo prodotti all'avanguardia con prezzi competitivi e certifichiamo assistenza qualificata. \
I nostri prodotti sono frutto di accurata selezione e arrivano dalla nostra elevata esperienza. \
Le conoscenze offerte dal nostro team non sono seconde a nessuno. Il nostro team di ricerca e sviluppo lavora costantemente per portare nuovi prodotti e soluzioni interessanti sul mercato";
const PAGE_PRODOTTI_SLIDE_1_URL = "https://www.shoppingclick.it/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide1.jpg";
const PAGE_PRODOTTI_SLIDE_1_TITOLO = "prodotti disponibili nel punto vendita";
const PAGE_PRODOTTI_SLIDE_1_SOTTOTITOLO = "trovate tutte le nostre selezioni";
const PAGE_PRODOTTI_SLIDE_2_URL = "https://www.shoppingclick.it/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide2.jpg";
const PAGE_PRODOTTI_SLIDE_2_TITOLO = "i migliori prodotti";
const PAGE_PRODOTTI_SLIDE_2_SOTTOTITOLO = "delle marche più prestigiose";
const PAGE_PRODOTTI_SLIDE_3_URL = "https://www.shoppingclick.it/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide3.jpg";
const PAGE_PRODOTTI_SLIDE_3_TITOLO = "prodotti ordinabili online";
const PAGE_PRODOTTI_SLIDE_3_SOTTOTITOLO = "qualsiasi variante in pochi giorni a casa vostra";

const PAGE_COUPON_TITOLO = "buoni virtuali "+ CUSTOMER + " per sconti promozionali"
const PAGE_COUPON_TESTO = "ti offriamo le migliori promozioni su categorie di prodotti. Puoi utilizzare il COUPON ad esempio ESTATE20 che corrisponde ad uno \
                sconto del 20% sui prodotti in promozione. Lo indicherai in fase di acquisto, alla pagina \
                carrello ed in automatico ti verrà applicato lo sconto previsto.";
const PAGE_COUPON_SLIDE_1_URL = "https://www.shoppingclick.it/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide4.jpg";
const PAGE_COUPON_SLIDE_1_TITOLO = "sconti promozionali per prezzi imbattibili";
const PAGE_COUPON_SLIDE_1_SOTTOTITOLO = "applicabili alle migliori selezioni";
const PAGE_COUPON_SLIDE_2_URL = "https://www.shoppingclick.it/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide5.jpg";
const PAGE_COUPON_SLIDE_2_TITOLO = "sconti promozionali per prezzi imbattibili";
const PAGE_COUPON_SLIDE_2_SOTTOTITOLO = "sulle delle marche più prestigiose";
const PAGE_COUPON_SLIDE_3_URL = "https://www.shoppingclick.it/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide6.jpg";
const PAGE_COUPON_SLIDE_3_TITOLO = "sconti promozionali per prezzi imbattibili";
const PAGE_COUPON_SLIDE_3_SOTTOTITOLO = "anche per qualsiasi variante ordiabile online";
                
const PAGE_GIFTCARD_TITOLO = "la logica delle carte prepagate"
const PAGE_GIFTCARD_TESTO = "EXPERTS elettronica rende disponibile i buoni regalo GIFT CARD. Potrai utilizzarli o regalarli a chi vuoi. \
                Chi li utilizza dispone di un buono che funziona come una ricarica telefonica. Ovvero ad agni acquisto scalerà quanto speso \
                fino al raggiungimento dell'importo della GIFT CARD. Il modo migliore per regalare prodotti e servizi di qualità";
const PAGE_GIFTCARD_SLIDE_1_URL = "https://www.shoppingclick.it/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide10.jpg";
const PAGE_GIFTCARD_SLIDE_1_TITOLO = "regala le nostre giftcard";
const PAGE_GIFTCARD_SLIDE_1_SOTTOTITOLO = "il gesto migliore";
const PAGE_GIFTCARD_SLIDE_2_URL = "https://www.shoppingclick.it/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide11.jpg";
const PAGE_GIFTCARD_SLIDE_2_TITOLO = "scegli il taglio delle nostre card";
const PAGE_GIFTCARD_SLIDE_2_SOTTOTITOLO = "un regalo per scalerare l'importo di acquisto";
const PAGE_GIFTCARD_SLIDE_3_URL = "https://www.shoppingclick.it/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide12.jpg";
const PAGE_GIFTCARD_SLIDE_3_TITOLO = "ogni regalo ha il suo valore";
const PAGE_GIFTCARD_SLIDE_3_SOTTOTITOLO = "la scelta personale aggiungerà valore agli acquisti";
                
                                
// prelievo IMMAGINI CLIENTE da www.shoppingclick.it (non utilizzato)
const URL_IMAGES_CUSTOMER = "https://www.shoppingclick.it/storage-images/customer-"+CUSTOMER_CODE+"/";

const URL_IMAGES_CATEGORY = "https://www.shoppingclick.it/storage-images/customer-0001/categories/";
const URL_IMAGES_PRODUCT = "https://www.shoppingclick.it/storage-images/customer-0001/products/";

// IMMAGINI CLIENTE da www.shoppingclick.it (non utilizzato)
const URL_IMAGES_CUST_LOGIN = "/login.jpg";
const URL_IMAGES_CUST_CHISIAMO = "/chisiamo.jpg";
const URL_IMAGES_CUST_CONTATTI = "/contatti.jpg";
const URL_IMAGES_CUST_COUPONS = "/coupons.jpg";
const URL_IMAGES_CUST_GIFTCARD = "/giftcard.jpg";

module.exports = { 
  ENABLE_SWITCH_STILE,
  SITE_STYLE_ACTIVE,
  DB_URI,
  API,
  GOOGLE_CLIENT_ID,
  GOOGLE_CLIENT_SECRET,
  TITLE_PRODUCT,
  URL_IMAGES_SITE,
  URL_IMAGES_CATEGORY,
  URL_IMAGES_PRODUCT,
  CUSTOMER,
  CUSTOMER_CODE,
  CUSTOMER_VIA,
  CUSTOMER_CITTA,
  CUSTOMER_EMAIL,
  CUSTOMER_SLIDE_URL_ARRAY,

  PAGE_LOGIN_TITOLO,
  PAGE_LOGIN_TESTO,
  PAGE_LOGIN_SLIDE_1_URL,
  PAGE_LOGIN_SLIDE_1_TITOLO,
  PAGE_LOGIN_SLIDE_1_SOTTOTITOLO,
  PAGE_LOGIN_SLIDE_2_URL,
  PAGE_LOGIN_SLIDE_2_TITOLO,
  PAGE_LOGIN_SLIDE_2_SOTTOTITOLO,
  PAGE_LOGIN_SLIDE_3_URL,
  PAGE_LOGIN_SLIDE_3_TITOLO,
  PAGE_LOGIN_SLIDE_3_SOTTOTITOLO,

  PAGE_REGISTRA_TITOLO,
  PAGE_REGISTRA_TESTO,
  PAGE_REGISTRA_SLIDE_1_URL,
  PAGE_REGISTRA_SLIDE_1_TITOLO,
  PAGE_REGISTRA_SLIDE_1_SOTTOTITOLO,
  PAGE_REGISTRA_SLIDE_2_URL,
  PAGE_REGISTRA_SLIDE_2_TITOLO,
  PAGE_REGISTRA_SLIDE_2_SOTTOTITOLO,
  PAGE_REGISTRA_SLIDE_3_URL,
  PAGE_REGISTRA_SLIDE_3_TITOLO,
  PAGE_REGISTRA_SLIDE_3_SOTTOTITOLO,

  PAGE_HOME_TITOLO,
  PAGE_HOME_TESTO,
  PAGE_HOME_SLIDE_1_URL,
  PAGE_HOME_SLIDE_1_TITOLO,
  PAGE_HOME_SLIDE_1_SOTTOTITOLO,
  PAGE_HOME_SLIDE_2_URL,
  PAGE_HOME_SLIDE_2_TITOLO,
  PAGE_HOME_SLIDE_2_SOTTOTITOLO,
  PAGE_HOME_SLIDE_3_URL,
  PAGE_HOME_SLIDE_3_TITOLO,
  PAGE_HOME_SLIDE_3_SOTTOTITOLO,

  PAGE_CHISIAMO_TITOLO,
  PAGE_CHISIAMO_TESTO, 
  PAGE_CHISIAMO_SLIDE_1_URL,
  PAGE_CHISIAMO_SLIDE_1_TITOLO,
  PAGE_CHISIAMO_SLIDE_1_SOTTOTITOLO,
  PAGE_CHISIAMO_SLIDE_2_URL,
  PAGE_CHISIAMO_SLIDE_2_TITOLO,
  PAGE_CHISIAMO_SLIDE_2_SOTTOTITOLO,
  PAGE_CHISIAMO_SLIDE_3_URL,
  PAGE_CHISIAMO_SLIDE_3_TITOLO,
  PAGE_CHISIAMO_SLIDE_3_SOTTOTITOLO,

  PAGE_CONTATTI_TITOLO,
  PAGE_CONTATTI_TESTO,
  PAGE_CONTATTI_SLIDE_1_URL,
  PAGE_CONTATTI_SLIDE_1_TITOLO,
  PAGE_CONTATTI_SLIDE_1_SOTTOTITOLO,
  PAGE_CONTATTI_SLIDE_2_URL,
  PAGE_CONTATTI_SLIDE_2_TITOLO,
  PAGE_CONTATTI_SLIDE_2_SOTTOTITOLO,
  PAGE_CONTATTI_SLIDE_3_URL,
  PAGE_CONTATTI_SLIDE_3_TITOLO,
  PAGE_CONTATTI_SLIDE_3_SOTTOTITOLO,  

  PAGE_BRAND_TITOLO,
  PAGE_BRAND_TESTO,
  PAGE_BRAND_SLIDE_1_URL,
  PAGE_BRAND_SLIDE_1_TITOLO,
  PAGE_BRAND_SLIDE_1_SOTTOTITOLO,
  PAGE_BRAND_SLIDE_2_URL,
  PAGE_BRAND_SLIDE_2_TITOLO,
  PAGE_BRAND_SLIDE_2_SOTTOTITOLO,
  PAGE_BRAND_SLIDE_3_URL,
  PAGE_BRAND_SLIDE_3_TITOLO,
  PAGE_BRAND_SLIDE_3_SOTTOTITOLO,

  PAGE_PRODOTTI_TITOLO,
  PAGE_PRODOTTI_TESTO,
  PAGE_PRODOTTI_SLIDE_1_URL,
  PAGE_PRODOTTI_SLIDE_1_TITOLO,
  PAGE_PRODOTTI_SLIDE_1_SOTTOTITOLO,
  PAGE_PRODOTTI_SLIDE_2_URL,
  PAGE_PRODOTTI_SLIDE_2_TITOLO,
  PAGE_PRODOTTI_SLIDE_2_SOTTOTITOLO,
  PAGE_PRODOTTI_SLIDE_3_URL,
  PAGE_PRODOTTI_SLIDE_3_TITOLO,
  PAGE_PRODOTTI_SLIDE_3_SOTTOTITOLO,

  PAGE_COUPON_TITOLO,
  PAGE_COUPON_TESTO,
  PAGE_COUPON_SLIDE_1_URL,
  PAGE_COUPON_SLIDE_1_TITOLO,
  PAGE_COUPON_SLIDE_1_SOTTOTITOLO,
  PAGE_COUPON_SLIDE_2_URL,
  PAGE_COUPON_SLIDE_2_TITOLO,
  PAGE_COUPON_SLIDE_2_SOTTOTITOLO,
  PAGE_COUPON_SLIDE_3_URL,
  PAGE_COUPON_SLIDE_3_TITOLO,
  PAGE_COUPON_SLIDE_3_SOTTOTITOLO,

  PAGE_GIFTCARD_TITOLO,
  PAGE_GIFTCARD_TESTO,
  PAGE_GIFTCARD_SLIDE_1_URL,
  PAGE_GIFTCARD_SLIDE_1_TITOLO,
  PAGE_GIFTCARD_SLIDE_1_SOTTOTITOLO,
  PAGE_GIFTCARD_SLIDE_2_URL,
  PAGE_GIFTCARD_SLIDE_2_TITOLO,
  PAGE_GIFTCARD_SLIDE_2_SOTTOTITOLO,
  PAGE_GIFTCARD_SLIDE_3_URL,
  PAGE_GIFTCARD_SLIDE_3_TITOLO,
  PAGE_GIFTCARD_SLIDE_3_SOTTOTITOLO,
   
  URL_IMAGES_CUSTOMER,
  URL_IMAGES_CUST_LOGIN,
  URL_IMAGES_CUST_CHISIAMO,
  URL_IMAGES_CUST_CONTATTI,
  URL_IMAGES_CUST_COUPONS,
  URL_IMAGES_CUST_GIFTCARD
};
