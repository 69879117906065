import {
  Navbar,
  Nav,
  Container,
  NavDropdown,
  Badge,
  Form,
  DropdownButton,
  Dropdown,
  Button,
  InputGroup,
} from "react-bootstrap";

import { LinkContainer } from "react-router-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../redux/actions/userActions.js";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getCategories } from "../redux/actions/categoryActions.js";
import socketIOClient from "socket.io-client";
import { setChatRooms, setSocket, setMessageReceived, removeChatRoom } from "../redux/actions/chatActions.js";

const config = require("../constants/constants.js")

const HeaderComponent1StyleB = () => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userRegisterLogin);
  const itemsCount = useSelector((state) => state.cart.itemsCount);
  const { categories } = useSelector((state) => state.getCategories);
  const { messageReceived } = useSelector((state) => state.adminChat);

  const [searchCategoryToggle, setSearchCategoryToggle] = useState("Tutte le categorie");
  const [searchQuery, setSearchQuery] = useState("");

  const navigate = useNavigate();

  const url_site_image_logo = config.URL_IMAGES_SITE+"logoMyShop.png";
  const url_customer_image_logo = config.URL_IMAGES_CUSTOMER+"logo-cust"+config.CUSTOMER_CODE+".png";  // logo-cust0001.png 

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  const submitHandler = (e) => {
     if (e.keyCode && e.keyCode !== 13) return;
     e.preventDefault();
     if (searchQuery.trim()) {
         if (searchCategoryToggle === "Tutte le categorie") {
             navigate(`/product-list/search/${searchQuery}`);
         } else {
             navigate(`/product-list/category/${searchCategoryToggle.replaceAll("/", ",")}/search/${searchQuery}`);
         }
     } else if (searchCategoryToggle !== "Tutte le categorie") {
         navigate(`/product-list/category/${searchCategoryToggle.replaceAll("/", ",")}`);
     } else {
         navigate("/product-list");
     }
  }

  useEffect(() => {
      if (userInfo.isAdmin) {
          var audio = new Audio("/audio/chat-msg.mp3");
          const socket = socketIOClient();
          socket.emit("admin connected with server", "Admin" + Math.floor(Math.random() * 1000000000000));
          socket.on("server sends message from client to admin", ({user, message}) => {
              dispatch(setSocket(socket));
        //   let chatRooms = {
        //     fddf54gfgfSocketID: [{ "client": "dsfdf" }, { "client": "dsfdf" }, { "admin": "dsfdf" }],
        //   };
            dispatch(setChatRooms(user, message));      
             dispatch(setMessageReceived(true));  
             audio.play();
          })
          socket.on("disconnected", ({reason, socketId}) => {
            //   console.log(socketId, reason)
            dispatch(removeChatRoom(socketId));
          })
          return () => socket.disconnect();
      }
  },[userInfo.isAdmin])

  return (
    <Navbar expand="sm" bg="dark" variant="dark" >

      <Container>
        
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
       
       <Navbar.Collapse id="responsive-navbar-nav">

          <Nav>

            <LinkContainer to="/home-menu">
                    <Nav.Link>Home</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/chi-siamo">
                    <Nav.Link>Chi siamo</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/prodotti">
                    <Nav.Link>Prodotti</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/brands">
                    <Nav.Link>Brand</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/coupons">
                    <Nav.Link>Coupon</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/gift-card">
                    <Nav.Link>Gift Card</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/contatti">
                    <Nav.Link>Contatti</Nav.Link>
            </LinkContainer>

          </Nav>

          </Navbar.Collapse>

      </Container>
    </Navbar>


);
};

export default HeaderComponent1StyleB
;
